/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/fonts/open-sans-v36-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../public/fonts/open-sans-v36-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

:root {
    --font-family: Open Sans, sans-serif;
    --primary-color: #0071d7;
    --primary-contrast-color: #ffffff;
    --success-color: #66b737;
    --charts-title-color: #0071d7;
    --charts-subtitle-color: #000000;
    --primary-light-color: #E5F1FB;
    --primary-text-color: #222;
    --text-on-primary-color: #ffffff;
    --secondary-color: #66AAE7;
    --gray-color: #979797;
    --background-color: #fff;
    --contrast-color: #cd1236;
    --card-backgound-color: white;
    --card-header-color: #0071d7;
    --card-header-background-color: white;
    --card-subheader-color: #222;
    --card-header-font-weight: 700;
    --navigation-background-color: #E5F1FB;
    --mui-palette-primary-main: #0071d7;
    --mui-palette-primary-mainChannel: #0071d7;
    --max-header-width: 1400px;
    --max-content-width: 1200px;
    --echart-color-5: rgba(0,94,174);
    --echart-color-6: rgba(112,153,204);
    --echart-color-7: rgba(165,186,221);
    --echart-color-8: rgba(186,183,183);
    --echart-color-9: rgba(221,218,218);
    --echart-color-1: rgba(170,29,29);
    --echart-color-2: rgba(255,0,0);
    --echart-color-3: rgba(255,192,0);
    --echart-color-4: rgba(146,208,80);

    --echart-color-1: rgba(0,94,174);
    --echart-color-2: rgba(112,153,204);
    --echart-color-3: rgba(165,186,221);
    --echart-color-4: rgba(186,183,183);

    --universe-mediaOwner-color: #60a3dd;
    --universe-mediaOwner-selected-color: #051a2c;
}

.rtr #content {
    margin-top: 116px;
}

.rtr a {
  color: var(--primary-color);
}

.rtr .MuiCard-root {
  border-radius: 7px !important;
  box-shadow: none !important;
  border: none !important;
}

.rtr .card-framed {
  background-color: var(--card-backgound-color) !important;
  border: 1px solid var(--primary-color) !important;
  box-shadow: 0 5px 15px rgba(0,0,0,.08) !important;
}

.rtr .card-text {
  padding: 16px;
}

.rtr .card-text .MuiCardHeader-root {
  padding: 0 0 10px 0;
}

.rtr .card-text .MuiCardContent-root {
  padding: 0;
}

.rtr .card-text p {
  margin: 25px 0;
}

.rtr .card-teaser {
  box-shadow: 0 2px 16px 0 rgba(67,67,67,.5) !important;
}

.rtr .card-teaser:hover {
  -webkit-transform: translateY(0.4rem) scale(.98);
  transform: translateY(0.4rem) scale(.98);
}

.rtr .card-search .MuiAccordion-root {
  border: none !important;
}

.rtr .card-search .MuiAccordionDetails-root {
  background: none !important;
}

.rtr .transition {
  transition: -webkit-transform .15s ease-out;
  transition: transform .15s ease-out;
  transition: transform .15s ease-out,-webkit-transform .15s
}

.rtr .MuiCard-root .MuiCard-root {
  padding: 16px !important;
  border: none !important;
}

.rtr .MuiCardHeader-title {
  font-weight: 700 !important;
  font-size: 24px !important;
  letter-spacing: 0.06px;
  line-break: auto;
  line-height: 25.2px;
}

.rtr .MuiButton-contained {
  border: 2px solid transparent;
  padding: 10px 20px!important;
  border-radius: 9px;
}

.rtr .MuiButtonBase-root:hover {
  background-color: var(--primary-contrast-color);
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
}

.rtr .MuiSwitch-switchBase:hover {
  border: none;
  background-color: inherit !important;
}

.rtr .topbar-content .MuiButtonBase-root:hover {
  color: #fff !important;
}

.rtr .horizontal-menu .MuiButton-outlined:hover   {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-width: 2px !important;
}

.rtr .accordion-icon {
  display: none;
}

.rtr .overview-accordian-header-text {
  color: var(--primary-text-color);
  font-weight: bold;
}

.rtr .MuiCardHeader-root {
  background-color: var(--card-header-background-color);
  color: var(--card-header-color);
  font-weight: var(--card-header-font-weight) !important;
  border-bottom: none;
}

.rtr .MuiAccordion-root {
  background-color: var(--background-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  border-radius: 9px;
  margin-bottom: 10px;
}

.rtr .MuiAccordionSummary-root {
  background-color: var(--background-color) !important;
  color: var(--primary-color) !important;
  border-radius: 9px;
}

.rtr .MuiAccordionCollapse-root {
  background-color: var(--background-color) !important;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  border-radius: 9px;
  margin-bottom: 10px;
}

.rtr .MuiAccordion-root:last-of-type {
  border-radius: 9px;
}

.rtr .MuiAccordionDetails-root {
  border-radius: 9px;
  padding: 20px;
}

.rtr .MuiAccordionSummary-expandIconWrapper {
  background-color: var(--primary-light-color);
  border-radius: 50%;
}

.rtr .MuiButton-root {
  text-transform: none;
}

.rtr .MuiOutlinedInput-root {
  border-radius: 9px !important;
}

.rtr .rdt_Pagination {
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

.rtr .rdt_TableHeader {
  background: none;
  padding: 0;
  margin-top: 10px;
}

.rtr .rdt_TableHeadRow > div {
  border-right: 1px solid var(--primary-color);
}

.rtr .rdt_TableCol {
  min-width: 5.4em !important;
}

.rtr .rdt_TableRow {
  border-bottom: none !important;
}

.rtr .MuiTable-root th, .rtr .rdt_TableHeadRow {
  border-bottom: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
}

.rtr .MuiTable-root td, .rtr .rdt_TableRow > div {
  border-bottom: none !important;
  border-right: 1px solid var(--primary-color);
}

.rtr .MuiTable-root td:last-child, .rtr .MuiTable-root th:last-child, .rtr .rdt_TableCol:last-child, .rtr .rdt_TableHeadRow:last-child, .rtr .rdt_TableCell:last-child {
  border-right: none;
}

.rtr .MuiTable-root tr:nth-child(odd), .rtr .rdt_TableRow:nth-child(odd) {
  background-color: rgba(0,113,215,.03) !important;
}

.rtr .MuiTable-root tr:nth-child(even), .rtr .rdt_TableRow:nth-child(even) {
  background-color: rgba(0,113,215,.1) !important;
}

.rtr .highlight-element {
  box-sizing: border-box;
  margin-top: 80px;
  margin-left: 0.7em;
  margin-right: 0.7em;
  background-color: var(--primary-color);
  background-image: url("../../public/img/rtr/Highlight-Background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 40px 20px 20px 20px;
}

.rtr .highlight-element h2 {
  font-size: 24px;
}

.rtr .highlight-element h3 {
  font-size: 56px;
  font-style: italic;
  margin: 0;
}

.rtr .highlight-element h4 {
  font-size: 24px;
  font-style: italic;
}

.rtr .MuiCardMedia-root.MuiCardMedia-img {
  width: auto;
  margin: auto;
}

.rtr .search-results-title {
  color: var(--primary-color);
  font-size: 28px;
  font-weight: 700;
}

.rtr .search-results-number {
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
}

.rtr .result-line {
  border: none;
  font-size: 20px;
  margin-bottom: 20px;
}

