.accordion-button:not(.collapsed) {
  background: transparent !important;
}

.top {
  border: 0 !important;
  margin-bottom: 0.5em;
}

.top-card-title {
  margin-bottom: 0 !important;
  font-size: 1em;
}

.tab-top-container-info-mobile {
  display: none;
}

.top-settings-mobile {
  margin: 0.25em 0;
}

.mobile-sunburst-echart {
  display: none;
}

.rdt_Table {
  min-width: 500px;
}

@media screen and (max-width: 600px) {
  .tab-top-container-info {
    display: none;
  }
  .tab-top-container-info-mobile {
    display: contents;
  }

  .sunburst-echart {
    display: none;
  }

  .mobile-sunburst-echart {
    display: block;
  }
}
