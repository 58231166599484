.period-slider .MuiSlider-markLabel, .period-slider .MuiSlider-markLabel .MuiSlider-markLabelActive {
    transform: translateX(-50%) rotate(-90deg) translateX(-20%) !important;
    line-height: 2.45 !important;
  }

.period-slider .MuiSlider-thumb, .amount-slider .MuiSlider-thumb {
    top:16%;
    margin-left: -10px;
}  

.period-slider.MuiSlider-root {
  margin-bottom: 60px;
}  